<template>
    <div>
        <b-card :title="$t('Add Employee')">
            <validation-observer ref="simpleRules">
                <b-row>
                    <b-col md=6 lg="4">
                        <b-form-group
                            :label="$t('First Name')"
                            label-for="mc-first-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="$t('First Name')"
                            >
                                <b-form-input
                                    id="mc-first-name"
                                    :placeholder="$t('First Name')"
                                    v-model="employee.first_name"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group
                            :label="$t('Last Name')"
                            label-for="mc-last-name"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                :name="$t('Last Name')"
                            >
                                <b-form-input
                                    id="mc-last-name"
                                    :placeholder="$t('Last Name')"
                                    v-model="employee.last_name"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="4">
                        <b-form-group
                            :label="$t('Phone')"
                            label-for="mc-phone"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|digits:11"
                                :name="$t('Phone')"
                            >
                                <b-form-input
                                    id="mc-phone"
                                    :placeholder="$t('Phone')"
                                    v-model="employee.phone"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6">
                        <b-form-group
                            :label="$t('Email')"
                            label-for="mc-email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|email"
                                :name="$t('Email')"
                            >
                                <b-form-input
                                    id="mc-email"
                                    :placeholder="$t('Email')"
                                    v-model="employee.email"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" lg="6">
                        <b-form-group
                            :label="$t('Password')"
                            label-for="mc-password"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required|min:6"
                                :name="$t('Password')"
                            >
                                <b-form-input
                                    id="mc-password"
                                    :placeholder="$t('Password')"
                                    v-model="employee.password"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr/>
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" @click="addEmployee">
                        <feather-icon class="font-medium-5 mr-1" icon="UserPlusIcon"/>
                        <span class="font-medium-3">{{ $t('Add') }}</span>
                    </b-button>
                </div>
            </validation-observer>
        </b-card>
    </div>
</template>

<script>
import {BButton, BCard, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
    required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

export default {
    name: "add",
    components: {
        BCard, BRow,
        BCol, BFormGroup,
        BButton,
        BFormCheckbox,
        BFormInput, vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            employee: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                password: '',
            },
        };
    },
    methods: {
        AddNewEmployee() {

        },
        addEmployee() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('employees/addEmployee', this.employee)
                        .then(res => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Successfully'),
                                        icon: 'InfoIcon',
                                        text: res.data.message,
                                        variant: 'success',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                            setTimeout(() => {
                                this.$router.push({name: 'employee-list'});
                            }, 1500);

                        })
                        .catch(err => {
                            this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: this.$t('Error'),
                                        icon: 'InfoIcon',
                                        text: err.response.data.message,
                                        variant: 'danger',
                                    },
                                },
                                {
                                    position: 'bottom-right',
                                })
                        })
                } else {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Error'),
                                icon: 'InfoIcon',
                                text: this.$t('Please Check Entered Data'),
                                variant: 'danger',
                            },
                        },
                        {
                            position: 'bottom-right',
                        })
                }
            })
        },
    },
}
</script>

<style scoped lang="scss">

</style>
